import { withDependencies } from '@wix/thunderbolt-ioc'
import { CompEventsRegistrarSym, CompRefAPI, CompRefAPISym, ICompEventsRegistrar, SdkHandlersProvider } from '@wix/thunderbolt-symbols'
import _ from 'lodash'

export const platformHandlersProvider = withDependencies(
	[CompRefAPISym, CompEventsRegistrarSym],
	(compRefAPI: CompRefAPI, compEventsRegistrar: ICompEventsRegistrar): SdkHandlersProvider<any> => {
		function serializeEvent(args: any = []) {
			if (args[0] && args[0].nativeEvent instanceof Event) {
				const [event, ...rest] = args
				const serializedEvent = _.omitBy(event, _.isObject)
				return [serializedEvent, ...rest]
			}
			return args
		}

		return {
			getSdkHandlers() {
				return {
					invokeCompRefFunction: async (compId: string, functionName: string, ...args: any) => {
						const compRef: any = await compRefAPI.getCompRefById(compId)
						return compRef[functionName](...args)
					},
					registerEvent(compId: string, eventName: string, eventHandler: Function) {
						compEventsRegistrar.register(compId, eventName, (...args: any) => eventHandler(serializeEvent(args)))
					}
				}
			}
		}
	}
)
